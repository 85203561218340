import React, { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { ADD_TOURNAMENT_MODAL, ADD_USER_MODAL } from "../../types/constants";

interface TournamentObj {
  message: string;
  tournament: [
    {
      _id: string;
      createdBy: string;
      venue: string;
      city: string;
      startDate: string;
      endDate: string;
      numberOfMatches: number;
    }

  ]
}

const Tournaments = () => {
  const ALL_TOURNAMENTS_COLUMNS = [
    {
      Header: "ID",
      accessor: "_id",
    },
    {
      Header: "Venue",
      accessor: "venue",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Start Date",
      accessor: "startDate",
    },
    {
      Header: "Number of Matches",
      accessor: "numberOfMatches",
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getAllTournaments = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/tournament/all`);

    return data.data;
  };

  const { data, error, isLoading } = useQuery<
    TournamentObj[] | undefined,
    any,
    TournamentObj[],
    any[]
  >(["getAllTournaments", pageIndex, cPageSize, cSortBy, desc, q], getAllTournaments);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_TOURNAMENTS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-tournaments">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />
        </div>
        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_TOURNAMENT_MODAL,
                  modalTitle: "Add a Tournament",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default Tournaments;
