import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface teamObj {
  venue: string;
  city: string;
  dateAndTime: string;
  tableRefree: string;
  floorRefree1: string;
  teamAName: string;
  teamBName: string;
  threeByThree: boolean;
}

const AddTeamModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: teamObj = {
    venue: "",
    city: "",
    dateAndTime: "",
    tableRefree: "",
    floorRefree1: "",
    teamAName: "",
    teamBName: "",
    threeByThree: false,
  };

  const handleSubmit = (team: teamObj) => {
    setIsSubmitting(true);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <div className="flex item-center gap-x-6">
          <Input label="Venue" id="venue" name="venue" type="text" />
          <Input label="City" id="city" name="city" type="text" />
        </div>
        <Input label="Date and Time" id="datetime" name="datetime" type="datetime-local" />
        <div className="flex item-center gap-x-6">
          <Input label="Table Refree" id="tablerefree" name="tablerefree" type="text" />
          <Input label="Floor Refree 1" id="floorrefree1" name="floorrefree1" type="text" />
        </div>
        <Input label="Team A Name" id="teama" name="teama" type="text" />
        <Input label="Team B Name" id="teamb" name="teamb" type="text" />
        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Add"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default AddTeamModal;
