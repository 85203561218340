import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Error404 from "../pages/Error404";

import { RootState } from "../redux/app";
import Header from "../components/Header/Header";
import Loader from "../components/Loader/Loader";
import Sidebar from "../components/Sidebar/Sidebar";
import UsersLayout from "./UsersLayout";
import TournamentsLayout from "./TournamentsLayout";
import Tournaments from "../pages/tournaments/Tournaments";
import Matches from "../pages/matches/Matches";
import MatchLayout from "./MatchLayout";
import Teams from "../pages/teams/Teams"
import TeamsLayout from "./TeamsLayout";
import Workouts from "../pages/workouts/Workouts";
import WorkoutsLayout from "./WorkoutsLayout";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const AllUsers = lazy(() => import("../pages/users/AllUsers"));

const AdminLayout = () => {
  const { show } = useSelector((state: RootState) => state.sidebar);

  return (
    <div className="layout relative">
      <div className="layout__content flex">
        <Sidebar />

        <main
          className={`main flex-1 flex flex-col ${show ? "ml-60" : "ml-20"
            } transition-all`}
          style={{
            minHeight: "100vh",
          }}
        >
          <Header />

          <div className="page-wrapper flex-1 mt-4 px-12 pl-8 pb-24 box-border">
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<WorkoutsLayout />} >
                  <Route index element={<Workouts />} />
                </Route>

                <Route path="/users" element={<UsersLayout />}>
                  <Route index element={<AllUsers />} />
                </Route>

                <Route path="/tournaments" element={<TournamentsLayout />}>
                  <Route index element={<Tournaments />} />
                </Route>

                <Route path="/matches" element={<MatchLayout />}>
                  <Route index element={<Matches />} />
                </Route>

                <Route path="/teams" element={<TeamsLayout />} >
                  <Route index element={<Teams />} />
                </Route>

                <Route path="/workouts" element={<WorkoutsLayout />}>
                  <Route index element={<Workouts />} />
                </Route>

                {/* /404 */}
                <Route path="/404" element={<Error404 />} />
                <Route
                  path="*"
                  element={<Navigate to="/404" replace={true} />}
                />
              </Routes>
            </Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
