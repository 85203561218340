import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface matchObj {
  venue: string;
  city: string;
  dateAndTime: Date;
  tableReferee: string;
  floorReferee1: string;
  teamAName: string;
  teamBName: string;
  threeByThree: boolean;
}

const AddMatchModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: matchObj = {
    venue: "",
    city: "",
    dateAndTime: new Date(),
    tableReferee: "",
    floorReferee1: "",
    teamAName: "",
    teamBName: "",
    threeByThree: false,
  };

  const handleSubmit = (match: matchObj) => {
    setIsSubmitting(true);
    console.log(match);

    axiosInstance
      .post(
        `/match/create`,
        match
      )
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getAllUsers"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: msg,
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <div className="flex item-center gap-x-6">
          <Input label="Venue" id="venue" name="venue" type="text" />
          <Input label="City" id="city" name="city" type="text" />
        </div>
        <Input label="Date and Time" id="dateAndTime" name="dateAndTime" type="datetime-local" />
        <div className="flex item-center gap-x-6">
          <Input label="Table Refree" id="tableReferee" name="tableReferee" type="text" />
          <Input label="Floor Refree 1" id="floorReferee1" name="floorReferee1" type="text" />
        </div>
        <Input label="Team A Name" id="teamAName" name="teamAName" type="text" />
        <Input label="Team B Name" id="teamBName" name="teamBName" type="text" />
        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Add"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default AddMatchModal;
