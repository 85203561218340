import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import useEscClose from "../../hooks/useEscClose";
import { hideModal } from "../../redux/features/modalSlice";
import { ModalObj } from "../../types";
import { ADD_USER_MODAL, ADD_TOURNAMENT_MODAL, ADD_MATCH_MODAL, ADD_TEAM_MODAL, ADD_PARTICIPANT_MODAL, ADD_SCORE_MODAL, ADD_PLAYER_MODAL } from "../../types/constants";
import AddUserModal from "../ModalComponents/AddUserModal";
import AddTournamentModal from "../ModalComponents/AddTournamentModal";
import AddMatchModal from "../ModalComponents/AddMatchModal";
import AddTeamModal from "../ModalComponents/AddTeamModal";
import AddParticipantModal from "../ModalComponents/AddParticipantModal";
import AddScoreModal from "../ModalComponents/AddScoreModal"
import AddPlayerModal from "../ModalComponents/AddPlayerModal"

interface Props extends ModalObj { }

const Modal = ({
  modalType,
  modalProps,
  modalTitle,
  showCloseBtn,
  bgColor,
  textColor,
}: Props) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideModal());
  };

  const ref = useEscClose(() => handleClose());

  let Component: React.FC<any> | null = null;

  switch (modalType) {
    case ADD_USER_MODAL:
      Component = AddUserModal;
      break;
    case ADD_TOURNAMENT_MODAL:
      Component = AddTournamentModal;
      break;
    case ADD_MATCH_MODAL:
      Component = AddMatchModal;
      break;
    case ADD_TEAM_MODAL:
      Component = AddTeamModal;
      break;
    case ADD_PARTICIPANT_MODAL:
      Component = AddParticipantModal;
      break;
    case ADD_SCORE_MODAL:
      Component = AddScoreModal;
      break;
    case ADD_PLAYER_MODAL:
      Component = AddPlayerModal;
      break;
    default:
      Component = null;
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <div
      className="backdrop fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-full h-full overflow-y-auto overflow-x-auto m-auto bg-black bg-opacity-60"
      style={{
        maxHeight: "100vh",
      }}
    >
      <div className="absolute top-16 flex flex-col items-center">
        <div
          className={`relative rounded modal flex flex-col`}
          style={{
            maxWidth: "80rem",
            background: bgColor ? bgColor : "#ffffff",
            color: textColor ? textColor : "inherit",
          }}
          ref={ref}
        >
          {showCloseBtn === true && (
            <button
              onClick={handleClose}
              type="button"
              className="modal__close-btn absolute right-2 z-10 top-3"
            >
              <MdClose color={textColor ? textColor : "inherit"} size={21} />
            </button>
          )}
          {modalTitle && (
            <div className="modal__title">
              <h3 className="text-xl font-semibold pl-4 px-2 py-4">
                {modalTitle}
              </h3>
            </div>
          )}

          <div className={`w-full z-50 ${showCloseBtn ? "mr-4" : ""}`}>
            {/* desctucturing undefinded value inside object, it will ignore */}

            {Component !== null && <Component {...modalProps} />}
          </div>
        </div>
        <div className="empty-space pb-14"></div>
      </div>
    </div>
  );
};

export default Modal;
