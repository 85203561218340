import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface scoreObj {
  workout1: {
    scoreA: number,
    scoreB: number,
    scoreC: number,
  },
  workout2: number,
  workout3: {
    scoreType: string,
    scoreA: number,
    scoreB: number,
    scoreC: number,
  }
}

const AddScoreModal = ({ player, workout1, workout2, workout3, editable }: { player: any, score: any, workout1: any, workout2: any, workout3: any, editable: any }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  console.log(player)

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: scoreObj = {
    workout1: {
      scoreA: workout1.scoreA,
      scoreB: workout1.scoreB,
      scoreC: workout1.scoreC,
    },
    workout2: workout2,
    workout3: {
      scoreType: workout3.scoreType,
      scoreA: workout3.scoreA,
      scoreB: workout3.scoreB,
      scoreC: workout3.scoreC,
    },
  };

  const handleSubmit = (score: scoreObj) => {
    setIsSubmitting(true);

    if (score.workout3.scoreType === "time") {
      if (score.workout3.scoreA > 6) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Score must be less than 6",
          })
        );
      }
    }

    axiosInstance
      .post(
        `/workout/score/add/${player}`,
        score,
      )
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getGlobalRank"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: msg,
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <label>Workout 1</label>
        <Input readonly={!editable} classes="mt-3" label="Score A" id="workout1.scoreA" name="workout1.scoreA" type="number" />
        <Input readonly={!editable} label="ScoreB" id="workout1.scoreB" name="workout1.scoreB" type="number" />
        <Input readonly={!editable} label="Score C" id="workout1.scorec" name="workout1.scoreC" type="number" />
        <label>Workout 2</label>
        <Input readonly={!editable} label="Score" classes="mt-3" id="workout2" name="workout2" type="number" />
        <label>Workout 3</label>
        <div className="flex space-x-3 items-center">
          {
            editable ? <Select options={[
              { value: "time", label: "Time" },
              { value: "reps", label: "Reps" },
            ]} label="Score Type" id="workout3.scoreType" classes="mt-3" name="workout3.scoreType" /> :
              <Input readonly={!editable} label="Score Type" classes="mt-3" id="workout3.scoreType" name="workout3.scoreType" type="text" />
          }
          <Input readonly={!editable} label="Score" classes="mt-3" id="workout3.scoreA" name="workout3.scoreA" type="number" />
        </div>
        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Add"
            isSubmitting={isSubmitting}
            classes={`text-sm ${editable ? "" : "hidden"}`}
          />
        </div>
      </Form>
    </Formik >
  );
};

export default AddScoreModal;
