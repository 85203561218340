import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal, showModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ADD_USER_MODAL, ADD_PLAYER_MODAL } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface participantObj {
  playerId: string;
  workout1: object;
  workout2: number;
  workout3: object;
  phoneNumber: string;
}

const AddParticipantModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: participantObj = {
    playerId: "",
    workout1: {},
    phoneNumber: "",
    workout2: 0,
    workout3: {
      scoreType: "time"
    },
  };

  const handleSubmit = (participant: participantObj) => {
    setIsSubmitting(true);

    if (participant.playerId.length > 0) {
      axiosInstance.post("/workout/participant/add", {
        playerId: participant.playerId,
        workout1: {},
        workout2: 0,
        workout3: {
          scoreType: "time"
        },
      }).then(
        (res) => {
          queryClient.invalidateQueries("participants");
          dispatch(
            addToast({
              kind: "SUCCESS",
              msg: "Participant added successfully",
            })
          );
          dispatch(hideModal());
        }
      ).catch((err: AxiosError) => {
        dispatch(
          addToast({
            kind: "ERROR",
            msg: err.message,
          })
        );
      }
      ).finally(() => {
        setIsSubmitting(false);
      }
      );
    } else {
      axiosInstance.get(`/match/getPlayerByPhoneNumber?mobileNumber=${participant.phoneNumber}`).then(
        (res) => {
          const playerId = res.data.playerDetails._id;
          axiosInstance.post("/workout/participant/add", {
            playerId: playerId,
            workout1: {},
            workout2: 0,
            workout3: {
              scoreType: "time"
            },
          }).then(
            (res) => {
              queryClient.invalidateQueries("getGlobalRank");
              dispatch(
                addToast({
                  kind: "SUCCESS",
                  msg: "Participant added successfully",
                })
              );
              dispatch(hideModal());
            }
          ).catch((err: AxiosError) => {
            dispatch(
              addToast({
                kind: "ERROR",
                msg: err.message,
              })
            );
          }
          ).finally(() => {
            setIsSubmitting(false);
          }
          );
        }).catch((err: AxiosError) => {
          dispatch(
            addToast({
              kind: "ERROR",
              msg: err.message,
            })
          );
        }).finally(() => {
          setIsSubmitting(false);
          queryClient.invalidateQueries("getGlobalRank");
        }
        );
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <Input label="Player Id" id="playerId" name="playerId" type="text" />
        <div className="flex items-center space-x-3 mb-4">
          <div className="h-1 bg-black w-full" />
          <p className="text-lg">OR</p>
          <div className="h-1 bg-black w-full" />
        </div>
        <Input label="Phone Number" id="phoneNumber" name="phoneNumber" type="tel" />
        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Add"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
        <p>New Player? <button onClick={() => {
          dispatch(hideModal());
          dispatch(showModal({
            modalType: ADD_PLAYER_MODAL,
            modalTitle: "Add Player",
            modalProps: {
            },
          }));
        }} className="underline underline-offset-2">Click Here</button></p>
      </Form>
    </Formik>
  );
};

export default AddParticipantModal;
