import { Option } from ".";

// toast types
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const INFO = "INFO";
export const WARNING = "WARNING";
export const DEFAULT = "DEFAULT";

// modal types
export const ADD_USER_MODAL = "ADD_USER_MODAL";
export const ADD_PLAYER_MODAL = "ADD_PLAYER_MODAL";
export const ADD_TOURNAMENT_MODAL = "ADD_TOURNAMENT_MODAL";
export const ADD_PARTICIPANT_MODAL = "ADD_PARTICIPANT_MODAL";
export const ADD_SCORE_MODAL = "ADD_SCORE_MODAL"
export const ADD_MATCH_MODAL = "ADD_MATCH_MODAL";
export const ADD_TEAM_MODAL = "ADD_TEAM_MODAL";

// user roles
export const USER_ROLES = {
  ADMIN: "ADMIN",
  ACCOUNT: "ACCOUNT",
  SALES: "SALES",
  FINANCE: "FINANCE",
  MARKETING: "MARKETING",
};

// user profile
export const USER_ROLES_CREATION = {
  ACCOUNTS: "ACCOUNTS",
  SALES: "SALES",
  PURCHASE: "PURCHASE",
  MARKETING: "MARKETING",
};

// Default Page sizes
export const DEFAULT_PAGE_SIZES = [10, 20, 50, 100];

// states
export const STATES: Option[] = [
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  {
    label: "Andhra Pradesh",
    value: "Andhra Pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
  },
  {
    value: "Assam",
    label: "Assam",
  },
  {
    value: "Bihar",
    label: "Bihar",
  },
  {
    value: "Chandigarh",
    label: "Chandigarh",
  },
  {
    value: "Chhattisgarh",
    label: "Chhattisgarh",
  },
  {
    value: "Dadra and Nagar Haveli",
    label: "Dadra and Nagar Haveli",
  },
  {
    value: "Daman and Diu",
    label: "Daman and Diu",
  },
  {
    value: "Delhi",
    label: "Delhi",
  },
  {
    value: "Goa",
    label: "Goa",
  },
  {
    value: "Gujarat",
    label: "Gujarat",
  },
  {
    value: "Haryana",
    label: "Haryana",
  },
  {
    value: "Himachal Pradesh",
    label: "Himachal Pradesh",
  },
  {
    value: "Jammu and Kashmir",
    label: "Jammu and Kashmir",
  },
  {
    value: "Jharkhand",
    label: "Jharkhand",
  },
  {
    value: "Karnataka",
    label: "Karnataka",
  },
  {
    value: "Kerala",
    label: "Kerala",
  },
  {
    value: "Ladakh",
    label: "Ladakh",
  },
  {
    value: "Lakshadweep",
    label: "Lakshadweep",
  },
  {
    value: "Madhya Pradesh",
    label: "Madhya Pradesh",
  },
  {
    value: "Maharashtra",
    label: "Maharashtra",
  },
  {
    value: "Manipur",
    label: "Manipur",
  },
  {
    value: "Meghalaya",
    label: "Meghalaya",
  },
  {
    value: "Mizoram",
    label: "Mizoram",
  },
  {
    value: "Nagaland",
    label: "Nagaland",
  },
  {
    value: "Odisha",
    label: "Odisha",
  },
  {
    value: "Puducherry",
    label: "Puducherry",
  },
  {
    value: "Punjab",
    label: "Punjab",
  },
  {
    value: "Rajasthan",
    label: "Rajasthan",
  },
  {
    value: "Sikkim",
    label: "Sikkim",
  },
  {
    value: "Tamil Nadu",
    label: "Tamil Nadu",
  },
  {
    value: "Telangana",
    label: "Telangana",
  },
  {
    value: "Tripura",
    label: "Tripura",
  },
  {
    value: "Uttar Pradesh",
    label: "Uttar Pradesh",
  },
  {
    value: "Uttarakhand",
    label: "Uttarakhand",
  },
  {
    value: "West Bengal",
    label: "West Bengal",
  },
];
