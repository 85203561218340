import React, { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { ADD_PARTICIPANT_MODAL, ADD_SCORE_MODAL, ADD_TOURNAMENT_MODAL, ADD_USER_MODAL } from "../../types/constants";

interface TournamentObj {
  _id: string,
  points: {
    one: number;
    two: number;
    three: number;
  },
  workout1: {
    scoreA: number;
    scoreB: number;
    scoreC: number;
  },
  workout2: number,
  workout3: {
    scoreType: string;
    scoreA: number;
    scoreB: number;
    scoreC: number;
  }
}

const Workouts = () => {
  const ALL_WORKOUTS_COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Jersey Number",
      accessor: "jerseyNumber",
    },
    {
      Header: "One",
      accessor: "points.one",
    },
    {
      Header: "Two",
      accessor: "points.two",
    },
    {
      Header: "Three",
      accessor: "points.three",
    },
    {
      Header: "Workout 1",
      accessor: "workout1.total"
    },
    {
      Header: "Workout 2",
      accessor: "workout2"
    },
    {
      Header: "Workout 3",
      accessor: "workout3.total.$numberDecimal"
    },
    {
      Header: "Total",
      accessor: "globalScore.$numberDecimal"
    }
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getGlobalRank = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/workout/rank/global`);

    return data.data;
  };

  const { data, error, isLoading } = useQuery<
    TournamentObj[] | undefined,
    any,
    TournamentObj[],
    any[]
  >(["getGlobalRank", pageIndex, cPageSize, cSortBy, desc, q], getGlobalRank);


  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_WORKOUTS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-workouts">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />
        </div>
        <div className="right flex space-x-2 items-center">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_PARTICIPANT_MODAL,
                  modalTitle: "Add a Participant",
                })
              );
            }}
          >
            <span className="mr-1">Add Participant</span> <MdAdd size={17} />
          </button>
          {selectedRows.length === 1 ?
            <>
              <button
                className="btn-primary flex items-center"
                onClick={() => {
                  dispatch(
                    showModal({
                      modalType: ADD_SCORE_MODAL,
                      modalTitle: "Add Scores",
                      modalProps: {
                        editable: true,
                        player: selectedRows[0],
                        workout1: data?.find((d) => d._id === selectedRows[0])?.workout1,
                        workout2: data?.find((d) => d._id === selectedRows[0])?.workout2,
                        workout3: data?.find((d) => d._id === selectedRows[0])?.workout3,
                      }
                    })
                  );
                }}
              >
                <span className="mr-1">Add Score</span> <MdAdd size={17} />
              </button>
              <button
                className="btn-primary flex items-center"
                onClick={() => {
                  dispatch(
                    showModal({
                      modalType: ADD_SCORE_MODAL,
                      modalTitle: "Add Scores",
                      modalProps: {
                        editable: false,
                        player: selectedRows[0],
                        workout1: data?.find((d) => d._id === selectedRows[0])?.workout1,
                        workout2: data?.find((d) => d._id === selectedRows[0])?.workout2,
                        workout3: data?.find((d) => d._id === selectedRows[0])?.workout3,
                      }
                    })
                  );
                }}
              >
                <span className="mr-1">View Score </span>
              </button>
            </>
            : ""}
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default Workouts;
