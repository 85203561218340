import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const WorkoutsLayout = () => {
  return (
    <div className="users h-full w-full">
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default WorkoutsLayout;
