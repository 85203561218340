import React, { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { ADD_MATCH_MODAL } from "../../types/constants";

interface subMatchObj {
  teamA: {
    name: string;
    players: any[];
    points: number;
    fouls: number;
    substitutions: number;
  };
  teamB: {
    name: string;
    players: any[];
    points: number;
    fouls: number;
    substitutions: number;
  };
  _id: string;
  createdBy: string;
  tableRefree: string;
  floorRefree1: string;
  status: string;
  venue: string;
  city: string;
  dateAndTime: string;
}

interface MatchObj {
  live: subMatchObj[];
  upcoming: subMatchObj[];
}

const Matches = () => {
  const ALL_MATCH_COLUMNS = [
    {
      Header: "ID",
      accessor: "_id",
    },
    {
      Header: "Team A",
      accessor: "teamA",
    },
    {
      Header: "Team B",
      accessor: "teamB",
    },
    {
      Header: "Venue",
      accessor: "venue",
    },
    {
      Header: "City",
      accessor: "city",
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getAllMatches = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/match/getAllMatches`);

    return data.data;
  };

  const { data, error, isLoading } = useQuery<
    MatchObj[] | undefined,
    any,
    MatchObj[],
    any[]
  >(["getAllMatches", pageIndex, cPageSize, cSortBy, desc, q], getAllMatches);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_MATCH_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-matches">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />
        </div>
        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_MATCH_MODAL,
                  modalTitle: "Add a Match",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default Matches;
