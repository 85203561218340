import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface playerObj {
  name: string;
  gender: string;
  mobileNumber: string;
  jerseyNumber: number;
  dateOfBirth: Date;
}

const AddPlayerModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: playerObj = {
    name: "",
    gender: "male",
    mobileNumber: "",
    jerseyNumber: 0,
    dateOfBirth: new Date(),
  };

  const handleSubmit = (player: playerObj) => {
    setIsSubmitting(true);

    axiosInstance
      .post(
        `/player/signup`,
        {
          name: player.name,
          mobileNumber: Number(player.mobileNumber),
          jerseyNumber: Number(player.jerseyNumber),
          dateOfBirth: player.dateOfBirth,
          gender: player.gender
        }
      )
      .then((response) => {
        // fetch id using mobile number
        axiosInstance.get(`match/getPlayerByPhoneNumber?mobileNumber=${player.mobileNumber}`).then((res) => {
          const playerId = res.data.playerDetails._id;
          axiosInstance.post("/workout/participant/add", {
            playerId: playerId,
            workout1: {},
            workout2: 0,
            workout3: {
              scoreType: "time"
            },
          }).then(
            (res) => {
              queryClient.invalidateQueries("getGlobalRank");
              dispatch(
                addToast({
                  kind: SUCCESS,
                  msg: "Player added successfully",
                })
              );
              dispatch(hideModal());
            }
          ).catch((err: AxiosError) => {
            dispatch(
              addToast({
                kind: ERROR,
                msg: err.message,
              })
            );
          }
          )
        })

      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        <Input label="Name" id="name" name="name" type="text" />
        <Select options={[
          {
            value: "male",
            label: "Male"
          },
          {
            value: "female",
            label: "Female"
          }
        ]} label="Gender" id="gender" name="gender" />
        <Input label="Mobile Number" id="mobileNumber" name="mobileNumber" type="text" />
        <Input label="Jersey Number" id="jerseyNumber" name="jerseyNumber" type="number" />
        <Input label="Date Of Birth" id="dateOfBirth" name="dateOfBirth" type="date" />
        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Add"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default AddPlayerModal;
